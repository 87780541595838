import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';

import { Box, Button, Image, Typography } from '../../components/ui';
import styles from './errorNotFound.module.css';
import PageLayout from '../../components/layout/Page';

import Container from '../../components/ui/container';
import Opps from '../../components/icons/Opps';
import { Link } from 'react-router-dom';
const ErrorNotFound = () => {
  const isLogged = useSelector((state: any) => state.user.isLogged);

  if (!isLogged) {
    return <Redirect to={`/auth/login`} />;
  }

  return (
    <PageLayout pageId='checkin-page' showNavbutton={false}>
      <Container className={styles.container}>
        {/* titulo evento */}
        <Opps />
        <Typography tag='h1' className={styles.error404}>
          ERROR 404
        </Typography>

        <Typography tag='p' className={styles.paragraph}>
          Oops! Página não encontrada.
        </Typography>
        <Link to='/'>
          <Button label='Voltar ao início' className={styles.button} />
        </Link>
      </Container>
    </PageLayout>
  );
};

export default ErrorNotFound;
