import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactHashRouter } from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import 'react-toastify/dist/ReactToastify.min.css';

/* Theme variables */
import './theme/variables.css';
import HomePage from './pages/home/HomePage';

import HomeOrLogin from './components/HomeOrLogin';
import Checkin from './pages/checkin';
import UpdateAttendee from './pages/updateAttendee';
import SearchAttendee from './pages/searchAttendee';
import Login from './pages/auth/login';
import Dashboard from './pages/dashboard';
import ErrorNotFound from './pages/ErrorNotFound';

const App = () => {
  return (
    <IonApp className={`${false ? 'dark-theme' : ''}`}>
      <IonReactHashRouter>
        <IonSplitPane contentId='main'>
          <IonRouterOutlet id='main' animated={false}>
            <Route path='/' component={HomeOrLogin} exact />
            <Route path='/auth/login' component={Login} exact />
            <Route path='/home' render={() => <HomePage />} />
            <Route path='/dashboard' render={() => <Dashboard />} />
            <Route path='/checkin' render={() => <Checkin />} />
            <Route path='/updateAttendee' render={() => <UpdateAttendee />} />
            <Route path='/searchAttendee' render={() => <SearchAttendee />} />
            <Route path='' render={() => <ErrorNotFound />} />
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactHashRouter>
    </IonApp>
  );
};

export default App;
