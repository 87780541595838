import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router';

import Dashboard from './Dashboard';

const HomePage = () => {
  const isLogged = useSelector((state) => state.user.isLogged);

  //const getData = () => {
  //  dispatch(dashboardActions.get());
  //};
  //const clear = () => dispatch(attendeesActions.clear());

  return isLogged ? <></> : <Redirect to={`/auth/login`} />;
};

export default HomePage;
