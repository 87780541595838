import React from 'react';
import { Box, Typography } from '../../../ui';
import ArrowBack from '../../../icons/ArrowBack';
import styles from './menu.module.css';
import ScanPeople from '../../../icons/ScanPeople';
import ArrowForward from '../../../icons/ArrowForward';
import Graph from '../../../icons/Graph';
import { Link, useHistory } from 'react-router-dom';
import Disconnect from '../../../icons/Disconnect';

interface MenuProps {
  showMenu: boolean;
  setShowMenu: (value: boolean) => void;
  logoff: () => void;
}

const MenuBody: React.FC<MenuProps> = ({ showMenu, setShowMenu, logoff }) => {
  const history = useHistory();

  const changePage = (path: string) => {
    setShowMenu(!showMenu);
    setTimeout(() => {
      history.push(path);
    }, 300);
  };
  const appPages = [
    {
      id: 'checkin-page',
      label: 'checkin',
      subLabel: 'Realize o credenciamento dos participantes.',
      path: '/checkin',
      icon: <ScanPeople width='32px' height='32px' />,
    },

    {
      id: 'dashboard-page',
      label: 'Dashboard',
      subLabel: 'Acompanhe o progresso do credenciamento.',
      path: '/dashboard',
      icon: <Graph />,
    },
  ];
  const menuClass = showMenu
    ? `${styles.menuMainBox} ${styles.open}`
    : styles.menuMainBox;

  return (
    <>
      <Box className={menuClass}>
        <Box
          className={styles.menuIconBackBox}
          onClick={() => setShowMenu(!showMenu)}
        >
          <ArrowBack
            className={styles.menuIconBack}
            width='32px'
            height='32px'
          />
        </Box>
        <Box className={styles.menuBodyBox}>
          <Typography className={styles.menuBodyTitle}>Menu</Typography>
          {appPages.map((page) => (
            <Box
              key={page.id}
              className={styles.menuBodyButton}
              onClick={() => changePage(page.path)}
            >
              {page.icon}
              <Box className={styles.menuBodyLabels}>
                <Typography className={styles.menuBodyButtonLabel}>
                  {page.label}
                </Typography>
                <Typography className={styles.menuBodyButtonSubLabel}>
                  {page.subLabel}
                </Typography>
              </Box>
              <ArrowForward className={styles.menuBodyArrowForward} />
            </Box>
          ))}
          <Box className={styles.menuLogoutButton} onClick={() => logoff()}>
            <Disconnect />
            <Typography className={styles.menuLogoutText}>
              Desconectar
            </Typography>
          </Box>
          <Box className={styles.menuVersionBox}>
            <Typography className={styles.menuVersionBoxText}>
              Versão 2.0.3
            </Typography>
            <Typography className={styles.menuVersionBoxText}>
              Powered by Ticket and Go
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default MenuBody;
