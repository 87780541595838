import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Box, Typography } from '../../components/ui';
import styles from './dashboard.module.css';

import PageLayout from '../../components/layout/Page';
import * as dashboardActions from './../../reducers/dashboard/actions';
import Refresh from '../../components/icons/Refresh';
import { useSelector } from 'react-redux';
import Container from '../../components/ui/container';
import Skeleton from '../../components/ui/skeleton';
import Charts from './charts';
import { Redirect } from 'react-router';

const Dashboard = () => {
  const dispatch = useDispatch();

  const { dashData, loading, evento_nome } = useSelector((state: any) => ({
    dashData: state.dashboard.data,
    loading: state.dashboard.loading,
    evento_nome: state.user?.evento?.nome || '',
  }));

  const [detail, setDetail] = useState(null);

  const getData: any = () => {
    setDetail(null);
    dispatch(dashboardActions.get());
  };
  const clear = () => dispatch(dashboardActions.clear());

  const chartDataFormatter = (data: any) => {
    return {
      labels: ['Ausentes', 'Presentes'],
      datasets: [
        {
          label: 'Participação',
          data: [data.total - data.checked, data.checked],
          backgroundColor: ['#CCC', '#09F'],
          borderColor: ['#bbb', '#09C'],
          borderWidth: 1,
        },
      ],
    };
  };

  useEffect(() => {
    getData();

    // Cleanup function
    return () => {
      clear();
    };
  }, []);

  const isLogged = useSelector((state: any) => state.user.isLogged);

  if (!isLogged) {
    return <Redirect to={`/auth/login`} />;
  }

  return (
    <PageLayout pageId='dashboard-page' showNavbutton={false}>
      {/* titulo dashboard com botão refresh */}
      <Box className={styles.dashboardBoxTitle}>
        <Typography tag='h1' className={styles.dashboardTitle}>
          {evento_nome}
        </Typography>
        <Refresh className={styles.refreshButton} onClick={getData} />
      </Box>
      {/* grid com os charts */}
      <Charts />
    </PageLayout>
  );
};

export default Dashboard;
