// Button.tsx
import React, { CSSProperties } from 'react';
import styles from './button.module.css';
import { IonSpinner } from '@ionic/react';

interface ButtonProps {
  label?: string;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  style?: CSSProperties;
}

const Button: React.FC<ButtonProps> = ({
  label,
  onClick,
  className,
  disabled = false,
  loading = false,
  style,
}) => {
  return (
    <button
      className={`${className} ${styles.button}`}
      style={style}
      onClick={onClick}
      disabled={disabled}
    >
      {loading ? <IonSpinner name='dots'></IonSpinner> : label}
    </button>
  );
};

export default Button;
